.col-btn-card-2 {
  position: relative !important;
  text-align: start;
  border: 0.5px solid grey;
  border-radius: 15px;
}

.col-btn-card-2:hover {
  border: 0.5px solid #00bfd6;
  border-radius: 15px;
}

.bg-azul {
  background-color: #035ba8;
  color: white;
}

.nombre-tarj-prod {
  overflow: hidden;
  line-height: 1rem;
  max-height: 2rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  font-size: 14px;
}

.nombre-marca {
  color: #035ba8;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  width: fit-content;
  padding: 1px 6px;
  border-radius: 6px;
  border: 2px solid #f7f7f7;
  margin-bottom: 8px !important;
}

.tag-envio-gratis {
  font-size: 12px;
  padding: 2px 8px 2px 8px;
  top: 124px;
  left: 1px;
}

.bg-gris-c {
  background-color: #f7f7f7;
}

.w-80 {
  width: 120px !important;
}

.disabled_input {
  pointer-events: none !important;
  cursor: none !important;
  padding-left: 10px !important;
}

.mr-5 {
  margin-left: 60px !important;
}

.slider-precios{
  width: 100%;
  height: 2px;
  background-color: gray;
}

.slider-precios .thumb{
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: #00bfd6;
  cursor: pointer;
  top: -7px;
  border: 0px solid;
  outline: none;
}

.select-buscar{
  width:80%;
  float: left;
}

.select-buscar-desktop{
  margin-right: -70px !important;
}

.collapsed{
  color: #00bfd6 !important;
}

.accordion-button{
  color: black;
}


/* input[type="range"]{
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}

input[type="range"]::-webkit-slider-runnable-track{
  -webkit-appearance: none;
  height: 5px;
}

input[type="range"]::-moz-range-track{
  -moz-appearance: none;
  height: 5px;
}

input[type="range"]::-ms-track{
  appearance: none;
  height: 5px;
}

input[type="range"]::-webkit-slider-thumb{
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #00bfd6;
  cursor: pointer;
  border-radius: 10px;
  pointer-events: auto !important;
  margin-top: -20px;
}

input[type="range"]::-moz-range-thumb{
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #00bfd6;
  cursor: pointer;
  border-radius: 10px;
  pointer-events: auto !important;
  margin-top: -20px;
}

input[type="range"]::-ms-range-thumb{
  appearance: none;
  width: 15px;
  height: 15px;
  background-color: #00bfd6;
  cursor: pointer;
  border-radius: 10px;
  pointer-events: auto !important;
  margin-top: -20px;
} */

/*.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background: #000;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-bottom: 20px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  background: url(../assets/img/circulo-rango-turq.png) no-repeat center center;
  background-size: 100%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 14px;
  height: 14px;
  background: url(../assets/img/circulo-rango-turq.png) no-repeat center center;
  background-size: 100%;
  border: 1px solid grey;
  cursor: pointer;
}*/

.page-link {
  margin-left: -1px;
}

.page-numbers {
  background-color: #131f35;
  color: white !important;
  border-color: white !important;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.bg-grey{
  background-color: #f7f7f7;
}

@media (max-width: 768px) {  
  .d-xs-none {
    display: none;
  }
}


.fixedwidthp{
  width: 200px;
  height: 220px;
}

a .ms-2.ps1.text-black p{
  margin-bottom: 2px !important;
}

.cuarenta{
  height: 40px !important;
}