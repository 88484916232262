.simuladorborder {
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
}

.rojo {
  color: red;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-20-p {
  font-size: 20px !important;
}

.div-gris-shadow {
  background: #f4f4f4;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

/* .rounded-pill[disabled] {
  background-color: #f1f1f1;
} */

input:disabled {
  background-color: #f1f1f1;
}

button:disabled {
  background-color: grey;
}

.fs-12 {
  font-size: 12px;
}

.bg-azul-black {
  background-color: #131f35;
}

@media (max-width: 500px) {
  .simuladorborder {
    margin-top: 10px;
  }
}