.fondo-404 {
    background-color: #131f35;
    background-size: cover;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  .titulo-404 {
    filter: brightness(0) invert(1);
  }
  
  .txt-404 {
    font-size: 50px;
  }
  
  .icon-404-a,
  .icon-404-b,
  .icon-404-c {
    filter: brightness(0) invert(1);
  }
  
  .icon-404-a {
    animation: movimiento-a 1.5s alternate infinite ease-in;
  }
  
  @keyframes movimiento-a {
    0% {
      transform: translateX(-20px) rotate(-90deg);
    }
  
    100% {
      transform: translateX(0px) rotate(0deg);
    }
  }
  
  .icon-404-b {
    animation: movimiento-b 1.5s alternate infinite ease-in;
  }
  
  @keyframes movimiento-b {
    0% {
      transform: translatey(30px) rotate(20deg);
    }
  
    100% {
      transform: translatey(15px) rotate(-40deg);
    }
  
    100% {
      transform: translatey(0px) rotate(0deg);
    }
  }
  
  .icon-404-c {
    animation: movimiento-c 1.5s alternate infinite ease-in;
  }
  
  @keyframes movimiento-c {
    0% {
      transform: translateX(20px) rotate(90deg);
    }
  
    100% {
      transform: translateX(0px) rotate(0deg);
    }
  }
  
  .rayo-404 {
    animation: caida-rayo 1.5s alternate infinite ease-in;
  }
  
  @keyframes caida-rayo {
    0% {
      transform: translatey(50px);
      opacity: 0.1;
    }
  
    20% {
      opacity: 1;
    }
  
    40% {
      opacity: 0.1;
    }
  
    60% {
      opacity: 1;
    }
  
    80% {
      opacity: 0.1;
    }
  
    100% {
      transform: translatey(0px);
      opacity: 1;
    }
  }