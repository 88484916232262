.tarjeta{
    border-color: 1px #D5D9D9;
    border-radius: 10px;
}

.bg-light{
    background-color: #F0F2F2 !important;
}

.rounded-top{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.rounded-bottom{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.text-secondary{
    color: #565959 !important;
}

.superior{
    height: auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* background-color: #F0F2F2; */
    background-color: rebeccapurple;
}