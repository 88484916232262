.col-banner-cuadrado {
  border: 2px solid #00bfd6;
  margin-right: 30px;
  width: 400px;
  height: 248px;
}

.btn-turq-hover:hover {
  background-color: #007b84;
  transform: scale(1.05);
  transition: 0.5s;
}

.btn-azul:hover {
  background-color: #007b84;
  transform: scale(1.05);
  transition: 0.5s;
}

.btn-sombra:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.384);
}

.text-verde {
  color: #00d954;
}

.borde-top-gris {
  border-top: 2px solid #eaeaea;
}

.accordion-header-cat {
  background-color: #f7f7f7;
}

.accordion-button-cat {
  background-color: #f7f7f7;
  border: 1px solid #c1c1c1;
  text-align: right !important;
  padding-left: 80px;
}

.accordion-button-cat::after {
  background: url(https://dreamtec.cl/uploads/assets/home/icono-acordeon-mas.png);
  width: 16px;
  height: 16px;
  background-size: 100%;
}

.accordion-button-cat:not(.collapsed)::after {
  background: url(https://dreamtec.cl/uploads/assets/home/icono-acordeon-menos.png);
  width: 16px;
  height: 16px;
  background-size: 100%;
  transform: rotate(180deg);
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: #c1c1c1 !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
  border-color: #c1c1c1 !important;
  background-color: #f7f7f7 !important;
}

.cat-lateral {
  color: #000;
  text-decoration: none;
}

.cat-lateral:hover {
  color: #000;
  font-weight: 700;
  text-decoration: none;
}

.cat-lateral:active {
  color: #000;
  font-weight: 900;
  text-decoration: none;
}

.text-center-movil {
  text-align: start;
}

@media (max-width: 400px) {
  .text-center-movil {
    text-align: center !important;
  }
}

.maxImgGrandes {
  width: 190px;
  height: 150px;
  /* width: 100%;
  height:auto; */
  /* border: #00b4d8 solid 2px; */
}

.colo {
  background-color: red;
}

.icoM {
  min-width: 30px;
  max-width: 71px;
  height: auto;
}

.icoMM {
  min-width: 30px;
  max-width: 30px;
  height: auto;
}

.border-white {
  border-top: 0px solid white;
}

.altob {
  height: 185px;
}

.btn-c-wsp-r {
  margin-bottom: 8px;
  position: fixed;
  z-index: 999;
  top: auto;
  right: 1px;
  bottom: 15%;
  background-color: transparent;
  border: none;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  transition: .5s;
  transform: scale(1);
}


.btn-c-wsp-r:hover {
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.436);
  transform: scale(1.1);
}

@media (min-width: 1500px) {
  .btn-c-wsp-r {
    /* top: 600px !important; */
    margin: 20px;
  }
}

@media (min-width: 1000px) {
  .btn-c-wsp-r {
    margin: 20px;
    width: 200px !important;
    height: 60px !important;
    bottom: 10% !important;
  }

}


.textowidth {
  width: 200px !important;
  height: 80px !important;
}

.btninwhs {
  width: 55px !important;
  height: 55px !important;
  margin-bottom: 40px !important;
}

@media (max-width: 1000px) {
  .textowidth {
    display: none;
  }
}


@media (max-width: 1400px) {

  #gamer-zone-banner-fondo .col-11,
  #home-office-banner-fondo .col-11,
  #smart-home-banner-fondo .col-11 {
    margin-top: 90px !important;
  }
}

@media (max-width: 1200px) {

  #gamer-zone-banner-fondo .col-11,
  #home-office-banner-fondo .col-11,
  #smart-home-banner-fondo .col-11 {
    margin-top: 75px !important;
  }
}

@media (max-width: 991px) {

  #gamer-zone-banner-fondo .col-11 {
    margin-top: 185px !important;
  }

  #home-office-banner-fondo .col-11 {
    margin-top: 165px !important;
  }

  #smart-home-banner-fondo .col-11 {
    margin-top: 160px !important;
  }

  #gamer-zone-banner-fondo {
    background: no-repeat url("https://dreamtec.cl/uploads/banners_categorias/gamerzone-movil.jpg") !important;
    background-size: contain !important;
  }

  #home-office-banner-fondo {
    background: no-repeat url("https://dreamtec.cl/uploads/banners_categorias/homeoffice-movil.jpg") !important;
    background-size: contain !important;
  }

  #smart-home-banner-fondo {
    background: no-repeat url("https://dreamtec.cl/uploads/banners_categorias/smarthome-movil.jpg") !important;
    background-size: contain !important;
  }

}

@media (max-width: 767px) {

  #home-office-banner-fondo .col-11 {
    margin-top: 135px !important;
  }

  #gamer-zone-banner-fondo .col-11 {
    margin-top: 150px !important;
  }

  #smart-home-banner-fondo .col-11 {
    margin-top: 135px !important;
  }

}

@media (max-width: 400px) {
  #gamer-zone-banner-fondo .col-11 {
    margin-top: 100px !important;
  }

  #home-office-banner-fondo .col-11 {
    margin-top: 100px !important;
  }

  #smart-home-banner-fondo .col-11 {
    margin-top: 100px !important;
  }
}

#gamer-zone-banner-fondo {
  background: no-repeat url("https://dreamtec.cl/uploads/banners_categorias/gamerzone-escritorio.jpg");
  background-size: contain;
}

#home-office-banner-fondo {
  background: no-repeat url("https://dreamtec.cl/uploads/banners_categorias/homeoffice-escritorio.jpg");
  background-size: contain;
}

#smart-home-banner-fondo {
  background: no-repeat url("https://dreamtec.cl/uploads/banners_categorias/smarthome-escritorio.jpg");
  background-size: contain;
}

@media (max-width: 991px) {

  .brevo-conversations--side-bottom {
    bottom: 120px;
}
}