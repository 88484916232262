.novedad-card {
  position: relative;
  width: 300px;
  height: 450px;
  border-radius: 10px;
  border: 1px solid #dee2e6;
  margin: 0 auto;
}

.tag-prod-skunov {
  width: 45%;
  background-color: white;
  border: 1px solid #c1c1c1;
  color: #003f66;
  font-size: 10px;
  padding: 2px 1px 2px 1px;
}

.textfs {
  font-size: 10px;
  font-weight: bold;
}

.imgwidth {
  height: 150px !important;
  border: 1px solid #ededed;
}

.imgcarrito {
  width: 30px !important;
  height: 20px !important;
}

.nombre-tarj-prod {
  height: 60px;
}

.nombre-marca {
  color: #035ba8;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  width: fit-content;
  padding: 1px 8px;
  border-radius: 10px;
  border: 2px solid #f7f7f7;
  margin-bottom: 8px !important;
  margin-top: 0px;
}

.icono-nombre-marca {
  font-size: 10px;
  color: #00bfd6;
}

.fixedwidth{
  height: 150px;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: '' !important;
}


.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: '' !important;
}
.bghoy{
  background-color: #00CF0D;
}
.ancho{
 height: 100px !important;
 text-decoration: none;
}
