.bg-turq {
  background-color: #00b4d8;
}

.no-underline {
    text-decoration: none;
  }
  


  .btn-c-wsp-r {
    margin-bottom: 8px;
    position: fixed;
    z-index: 999;
    top: auto;
    right: 1px;
    bottom: 15%;
    background-color: transparent;
    border: none;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    transition: .5s;
    transform: scale(1);
  }
  
  
  .btn-c-wsp-r:hover {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.436);
    transform: scale(1.1);
  }
  
  @media (min-width: 1500px) {
    .btn-c-wsp-r {
      /* top: 600px !important; */
      margin: 20px;
    }
  }
  
  @media (min-width: 1000px) {
    .btn-c-wsp-r {
      margin: 20px;
      width: 200px !important;
      height: 60px !important;
      bottom: 10% !important;
    }
    
  }
  