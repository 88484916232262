.modalMensaje {
    position:fixed;
    bottom:15%;
    right:0px;
    border: 100px black;
    background: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    z-index: 1300;
    outline: 0px;
    width: 300px;
    height: auto;
    border-radius: 0.75rem;
    font-size: 14px !important;
    font-weight: 500 !important;
    border: 1px grey;
  }

  .modalMensaje .barra{
    height: 5px;
    width: 100%;
  }

  .fondo-rojo{
    background-color: rgb(220, 53, 59);
  }

  .fondo-verde{
    background-color: rgb(102, 187, 106);
  }

  .fondo-celeste{
    background-color: rgb(0, 191, 214);
  }