/*
Proyecto: sistema de ventas Dreamtec
Versión: 1.2
Año: 2023
Author: PyP Informática SpA
web site author: www.pypinformatica.cl
*/

/*nav*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Archivo CSS (puede ser global o específico) */


body {
  /* background-color: #ededed; */
  font-family: 'Roboto', sans-serif !important;
  overflow-y: auto !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25;
}

.input-group.buscador input {
  border-right: 0px !important;
}

.input-group.buscador span {
  border: 1px solid rgb(210, 210, 210) !important;
  border-left: 0px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-decoration-none {
  text-decoration: none;
}

.btn-cerrar-sesion-nav {
  font-size: 12px;
  color: #A1AABA;
}

.login {
  max-width: 500px;
  border-radius: 10px;
}

.bg-gris-claro {
  background-color: #ededed;
}

.border-radius-left {
  border-top-left-radius: 21px !important;
  border-bottom-left-radius: 21px !important;
}


.border-radius-right {
  border-top-right-radius: 21px !important;
  border-bottom-right-radius: 21px !important;
}

.disabled-select {
  cursor: none !important;
  pointer-events: none !important;
  background-color: #A1AABA !important;
}

.by {
  color: gray;
}

.logoPyP {
  width: 150px;
}

.wrapper1 {
  transform: rotateX(180deg);
  overflow-x: auto;
  overflow-y: hidden;
}

.wrapper2 {
  transform: rotateX(180deg);
}

.logoAdmin {
  width: 220px;
}

@media (max-width: 300px) {
  .logoAdmin {
    width: 100% !important;
  }
}

.css-1iu09ww {
  padding: 32px 24px 24px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}

.buttonAdmin {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  width: 100%;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  background-image: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  box-shadow: rgba(26, 115, 232, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(26, 115, 232, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(26, 115, 232, 0.15) 0rem 0.0625rem 0.3125rem 0rem;
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.buttonAdmin:hover {
  box-shadow: #787878e7 2px 4px 7px !important;
}

.placeholder {
  color: rgb(194, 193, 193);
}

input[type=text],
input[type=number],
textarea {
  border: 1px solid var(--gris) !important;
  border-radius: 10px;
  height: 40px;
  padding-left: 10px;
}

.nav-link {
  margin: 5px 0px;
}

.nav-link.active {
  font-size: 14px !important;
  font-weight: 300 !important;
  opacity: 1;
  background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 0rem 0rem;
  border-radius: 0.375rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}

.nav-link:hover {
  background-color: #A1AABA !important;
}

.mem {
  margin-right: 20px !important;
}

.mmem {
  margin-right: 25px !important;
}

/*.nav*/

/*sidebar*/
.logoAdminMenu {
  width: 150px;
  margin-left: 30px;
}

.menuVertical {
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  border: none;
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
  transform: translateX(0px);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  width: 250px;
  height: 100vh;
  margin: 1rem;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border: none;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.nav-pills {
  margin: 0px 10px !important;
}

/*.sidebar*/

.regular {
  margin-left: 0px;
  padding: 20px;
}

.boton-menu-admin-slider {
  position: fixed !important;
  top: 4%;
  left: 10px;
}

.offcanvas {
  width: 250px !important;
}

.fondo-dreamtec {
  background-color: rgba(13, 202, 240) !important;
  width: auto;
}

.shadow {
  padding: 20px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
  height: 100%;
}

h1 {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 300;
}

.bg-azul {
  background-color: #0d6efd !important;
}

.bg-azul-linear {
  background: #0d6efd !important;
}

.fs-16 {
  font-size: 16px !important;
}

h1>span {
  font-weight: 600;
}

h2 {
  margin: 0px;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(26, 115, 232);
  font-weight: 600;
  margin-top: 20px;
}

h2>span {
  font-weight: 400;
  color: rgb(52, 71, 103);
}

.brDream,
input[type=text],
input[type=number],
textarea {
  border: 1px solid rgb(210, 210, 210) !important;
  /*resize: none !important;*/
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.alert,
input[type=file] {
  font-size: 13px;
}

/*botones*/

.agregar {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  width: 100%;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  box-shadow: rgba(52, 71, 103, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(52, 71, 103, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(52, 71, 103, 0.15) 0rem 0.0625rem 0.3125rem 0rem;
  height: 2.4375rem;
  background-image: initial;
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: rgb(255, 255, 255);
  color: rgb(52, 71, 103);
  border: 0.0625rem solid rgb(52, 71, 103);
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.btnCelesteDreamtec,
.btnGreen,
.btnOrange,
.btnRed,
.cancel {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
  /*background-image: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));*/
  background-position-y: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: rgb(255, 255, 255);
  box-shadow: rgba(52, 71, 103, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(52, 71, 103, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(52, 71, 103, 0.15) 0rem 0.0625rem 0.3125rem 0rem;
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.btnOrange {
  background: linear-gradient(195deg, rgb(236, 164, 93), rgb(233, 129, 24));
}

.btnCelesteDreamtec {
  background: linear-gradient(195deg, rgb(0, 191, 214), rgb(13, 202, 240));
}

.cancel {
  background: linear-gradient(195deg, rgb(159, 158, 156), rgb(112, 108, 105));
}

.btnRed {
  background: linear-gradient(195deg, rgb(220, 53, 59), rgb(210, 47, 52));
}

.actualizar,
.agregarProducto {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  transition: all 150ms ease-in 0s;
  background-color: transparent;
  min-height: 2.5rem;
  box-shadow: none;
  padding: 0.625rem 1.5rem;
  color: rgb(244, 67, 53);
  background-size: 150% !important;
  background-position-x: 25% !important;
}

.agregarProducto {
  /*color: rgb(52, 71, 103);*/
  color: rgb(5, 184, 20);
}

/*table*/
.id {
  position: relative;
  text-align: center;
  opacity: 0.7;
  background: transparent;
  color: rgb(123, 128, 154);
  box-shadow: none;
  font-size: 0.65rem;
  font-weight: 700;
  text-transform: uppercase;
}

table {
  border-collapse: initial !important;
}

.number,
.nameNumber {
  margin: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  display: block;
  opacity: 1;
  text-transform: none;
  text-decoration: none;
  color: rgb(52, 71, 103);
  font-weight: 600;
  text-align: center;
}

.nameNumber {
  font-weight: 400;
  text-align: start;
}

.fotoCat {
  height: 50px;
  width: 120px;
}