.bg-skyblue {
  background-color: #131f35;
  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.977);
}

.hooverturq {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.hooverturq:hover {
  color: #00bfd6;
}

.nonehyper {
  text-decoration: none;
  color: white;
}

.nonehyper:hover p {
  color: #00bfd6 !important;
}

.separadorcategorias {
  height: 70px;
  border-right: white solid 2px;

}

.hoversubcategoria {
  color: white;
}

.hoversubcategoria:hover {

  filter: brightness(0) invert(1);
}

@media (max-width: 700px) {
  .d-xs-none {
    display: none;
  }
}


@media (max-width: 1200px) {

  .hooverturq p { 
    font-size: 12px !important;
  }

}