.w-carrito {
  width: 300px !important;
  z-index: 99999;
  left: 70%;
  /* right: 8%; */
}

.bg-cream {
  background-color: #f5f5f5 !important;
}

ul {
  list-style-type: none;
}

.div-circulo-detalle-producto {
  color: white;
  background-color: #00bfd6;
  border: 2px solid white;
  border-radius: 100%;
  position: relative;
  left: 80% !important;
  top: 15%;
  width: 22px !important;
  height: 24px !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.paddingul {
  padding: 0px !important;
  ;
}

.div-white-img {
  width: 70px;
  margin: 0 auto;
  background: white;
  padding: 10px;
}

.cantidad-productos-carro {
  color: white;
  background-color: red;
  border: 2px solid white;
  border-radius: 20px;
  width: 20px;
  font-size: 12px;
}

.max-height-2 {
  max-height: 300px;
  overflow-y: auto;
}

.roundedq {
  margin-top: 75px !important;
  border-radius: 10px;
}

@media (max-width: 1700px) {

  .w-carrito {
    left: 75%;
  }

}


@media (max-width: 1250px) {
  .w-carrito {
    left: 72%;
  }
}

@media (max-width: 1100px) {
  .w-carrito {
    left: 68%;
  }
}

@media (max-width: 991px) {
  .roundedq {
    margin-top: 10px !important;
    border-radius: 10px;
  }

  .w-carrito{
    left: unset;
    right: 2%;
  }
}

@media screen and (max-width: 768px) {

  /* .pxsmv{
    top: -90% !important;
    left: 110% !important;
    position: relative !important;
  } */
  .pricesmall {
    font-size: 10px !important;
  }
}

.boxshadow {
  box-shadow: 3px 5px 10px black;
}

@media (max-width: 500px){
  .logo-header-movil{
    height: auto !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}


@media (max-width: 310px) {
  .w-carrito {
    width: 90% !important;
  }
}