@font-face {
    font-family: summernote;
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(font/summernote.eot);
    src: url(https://dreamtec.cl/summernote.eot?#iefix) format("embedded-opentype"), url(https://dreamtec.cl/summernote.woff2) format("woff2"), url(https://dreamtec.cl/summernote.woff) format("woff"), url(https://dreamtec.cl/summernote.ttf) format("truetype")
}

[class*=" note-icon"]:before,
[class^=note-icon]:before {
    display: inline-block;
    font-family: summernote;
    font-style: normal;
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    speak: none
}

.note-icon-fw {
    text-align: center;
    width: 1.25em
}

.note-icon-border {
    border: .08em solid #eee;
    border-radius: .1em;
    padding: .2em .25em .15em
}

.note-icon-pull-left {
    float: left
}

.note-icon-pull-right {
    float: right
}

.note-icon.note-icon-pull-left {
    margin-right: .3em
}

.note-icon.note-icon-pull-right {
    margin-left: .3em
}

.note-icon-align:before {
    content: ""
}

.note-icon-align-center:before {
    content: ""
}

.note-icon-align-indent:before {
    content: ""
}

.note-icon-align-justify:before {
    content: ""
}

.note-icon-align-left:before {
    content: ""
}

.note-icon-align-outdent:before {
    content: ""
}

.note-icon-align-right:before {
    content: ""
}

.note-icon-arrow-circle-down:before {
    content: ""
}

.note-icon-arrow-circle-left:before {
    content: ""
}

.note-icon-arrow-circle-right:before {
    content: ""
}

.note-icon-arrow-circle-up:before {
    content: ""
}

.note-icon-arrows-alt:before {
    content: ""
}

.note-icon-arrows-h:before {
    content: ""
}

.note-icon-arrows-v:before {
    content: ""
}

.note-icon-bold:before {
    content: ""
}

.note-icon-caret:before {
    content: ""
}

.note-icon-chain-broken:before {
    content: ""
}

.note-icon-circle:before {
    content: ""
}

.note-icon-close:before {
    content: ""
}

.note-icon-code:before {
    content: ""
}

.note-icon-col-after:before {
    content: ""
}

.note-icon-col-before:before {
    content: ""
}

.note-icon-col-remove:before {
    content: ""
}

.note-icon-eraser:before {
    content: ""
}

.note-icon-float-left:before {
    content: ""
}

.note-icon-float-none:before {
    content: ""
}

.note-icon-float-right:before {
    content: ""
}

.note-icon-font:before {
    content: ""
}

.note-icon-frame:before {
    content: ""
}

.note-icon-italic:before {
    content: ""
}

.note-icon-link:before {
    content: ""
}

.note-icon-magic:before {
    content: ""
}

.note-icon-menu-check:before {
    content: ""
}

.note-icon-minus:before {
    content: ""
}

.note-icon-orderedlist:before {
    content: ""
}

.note-icon-pencil:before {
    content: ""
}

.note-icon-picture:before {
    content: ""
}

.note-icon-question:before {
    content: ""
}

.note-icon-redo:before {
    content: ""
}

.note-icon-rollback:before {
    content: ""
}

.note-icon-row-above:before {
    content: ""
}

.note-icon-row-below:before {
    content: ""
}

.note-icon-row-remove:before {
    content: ""
}

.note-icon-special-character:before {
    content: ""
}

.note-icon-square:before {
    content: ""
}

.note-icon-strikethrough:before {
    content: ""
}

.note-icon-subscript:before {
    content: ""
}

.note-icon-summernote:before {
    content: ""
}

.note-icon-superscript:before {
    content: ""
}

.note-icon-table:before {
    content: ""
}

.note-icon-text-height:before {
    content: ""
}

.note-icon-trash:before {
    content: ""
}

.note-icon-underline:before {
    content: ""
}

.note-icon-undo:before {
    content: ""
}

.note-icon-unorderedlist:before {
    content: ""
}

.note-icon-video:before {
    content: ""
}

.note-frame {
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
    font-family: sans-serif;
    border-radius: 4px
}

.note-toolbar {
    padding: 10px 5px;
    color: #333;
    background-color: #f5f5f5;
    border-bottom: 1px solid;
    border-color: #ddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px
}

.note-btn-group {
    position: relative;
    display: inline-block;
    margin-right: 8px
}

.note-btn-group>.note-btn-group {
    margin-right: 0
}

.note-btn-group>.note-btn:first-child {
    margin-left: 0
}

.note-btn-group .note-btn+.note-btn,
.note-btn-group .note-btn+.note-btn-group,
.note-btn-group .note-btn-group+.note-btn,
.note-btn-group .note-btn-group+.note-btn-group {
    margin-left: -1px
}

.note-btn-group>.note-btn-group:not(:first-child)>.note-btn,
.note-btn-group>.note-btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.note-btn-group>.note-btn-group:not(:last-child)>.note-btn,
.note-btn-group>.note-btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.note-btn-group.open>.note-dropdown {
    display: block
}

.note-btn {
    display: inline-block;
    font-weight: 400;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    outline: 0;
    color: #333;
    background-color: #fff;
    border: 1px solid #dae0e5;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.note-btn.focus,
.note-btn:focus,
.note-btn:hover {
    color: #333;
    background-color: #ebebeb;
    border-color: #dae0e5
}

.note-btn.disabled.focus,
.note-btn.disabled:focus,
.note-btn[disabled].focus,
.note-btn[disabled]:focus,
fieldset[disabled] .note-btn.focus,
fieldset[disabled] .note-btn:focus {
    background-color: #fff;
    border-color: #dae0e5
}

.note-btn.active,
.note-btn.focus,
.note-btn:active,
.note-btn:focus,
.note-btn:hover {
    color: #333;
    text-decoration: none;
    border: 1px solid #dae0e5;
    background-color: #ebebeb;
    outline: 0;
    border-radius: 1px
}

.note-btn.active,
.note-btn:active {
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}

.note-btn.disabled,
.note-btn[disabled],
fieldset[disabled] .note-btn {
    cursor: not-allowed;
    -webkit-opacity: .65;
    -khtml-opacity: .65;
    -moz-opacity: .65;
    opacity: .65;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=65);
    filter: alpha(opacity=65);
    box-shadow: none
}

.note-btn>span.note-icon-caret:first-child {
    margin-left: -1px
}

.note-btn>span.note-icon-caret:nth-child(2) {
    padding-left: 3px;
    margin-right: -5px
}

.note-btn-primary {
    background: #fa6362;
    color: #fff
}

.note-btn-primary.focus,
.note-btn-primary:focus,
.note-btn-primary:hover {
    color: #fff;
    text-decoration: none;
    border: 1px solid #dae0e5;
    background-color: #fa6362;
    border-radius: 1px
}

.note-btn-block {
    display: block;
    width: 100%
}

.note-btn-block+.note-btn-block {
    margin-top: 5px
}

input[type=button].note-btn-block,
input[type=reset].note-btn-block,
input[type=submit].note-btn-block {
    width: 100%
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none
}

.close {
    float: right;
    font-size: 21px;
    line-height: 1;
    color: #000;
    opacity: .2
}

.close:hover {
    -webkit-opacity: 1;
    -khtml-opacity: 1;
    -moz-opacity: 1;
    -ms-filter: alpha(opacity=100);
    filter: alpha(opacity=100);
    opacity: 1
}

.note-dropdown {
    position: relative
}

.note-color .dropdown-toggle {
    width: 30px;
    padding-left: 5px
}

.note-dropdown-menu {
    display: none;
    min-width: 100px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    text-align: left;
    background: #fff;
    border: 1px solid #e2e2e2;
    padding: 5px;
    background-clip: padding-box;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .06)
}

.note-dropdown-menu>:last-child {
    margin-right: 0
}

.note-btn-group.open .note-dropdown-menu,
.note-dropdown-item {
    display: block
}

.note-dropdown-item:hover {
    background-color: #ebebeb
}

a.note-dropdown-item,
a.note-dropdown-item:hover {
    margin: 5px 0;
    color: #000;
    text-decoration: none
}

.note-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1050;
    -webkit-opacity: 1;
    -khtml-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
    filter: alpha(opacity=100);
    display: none
}

.note-modal.open {
    display: block
}

.note-modal-content {
    position: relative;
    width: auto;
    margin: 30px 20px;
    border: 1px solid rgba(0, 0, 0, .2);
    background: #fff;
    background-clip: border-box;
    outline: 0;
    border-radius: 5px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.note-modal-header {
    padding: 10px 20px;
    border: 1px solid #ededef
}

.note-modal-body {
    position: relative;
    padding: 20px 30px
}

.note-modal-body kbd {
    border-radius: 2px;
    background-color: #000;
    color: #fff;
    padding: 3px 5px;
    font-weight: 700;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.note-modal-footer {
    height: 40px;
    padding: 10px;
    text-align: center
}

.note-modal-footer a {
    color: #337ab7;
    text-decoration: none
}

.note-modal-footer a:focus,
.note-modal-footer a:hover {
    color: #23527c;
    text-decoration: underline
}

.note-modal-footer .note-btn {
    float: right
}

.note-modal-title {
    font-size: 20px;
    color: #42515f;
    margin: 0;
    line-height: 1.4
}

.note-modal-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1040;
    background: #000;
    -webkit-opacity: .5;
    -khtml-opacity: .5;
    -moz-opacity: .5;
    opacity: .5;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
    filter: alpha(opacity=50);
    display: none
}

.note-modal-backdrop.open {
    display: block
}

@media(min-width:768px) {
    .note-modal-content {
        width: 600px;
        margin: 30px auto
    }
}

@media(min-width:992px) {
    .note-modal-content-large {
        width: 900px
    }
}

.note-modal .note-help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373
}

.note-modal .note-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.note-modal .note-nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

.note-modal .note-nav-link:focus,
.note-modal .note-nav-link:hover {
    color: #0056b3;
    text-decoration: none
}

.note-modal .note-nav-link.disabled {
    color: #868e96
}

.note-modal .note-nav-tabs {
    border-bottom: 1px solid #ddd
}

.note-modal .note-nav-tabs .note-nav-item {
    margin-bottom: -1px
}

.note-modal .note-nav-tabs .note-nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.note-modal .note-nav-tabs .note-nav-link:focus,
.note-modal .note-nav-tabs .note-nav-link:hover {
    border-color: #e9ecef #e9ecef #ddd
}

.note-modal .note-nav-tabs .note-nav-link.disabled {
    color: #868e96;
    background-color: transparent;
    border-color: transparent
}

.note-modal .note-nav-tabs .note-nav-item.show .note-nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #ddd #ddd #fff
}

.note-modal .note-tab-content {
    margin: 15px auto
}

.note-modal .note-tab-content>.note-tab-pane,
.note-modal .note-tab-content>.note-tab-pane:target~.note-tab-pane:last-child {
    display: none
}

.note-modal .note-tab-content>.note-tab-pane:target,
.note-modal .note-tab-content>:last-child {
    display: block
}

.note-form-group {
    padding-bottom: 20px
}

.note-form-group:last-child {
    padding-bottom: 0
}

.note-form-label {
    display: block;
    width: 100%;
    font-size: 16px;
    color: #42515f;
    margin-bottom: 10px;
    font-weight: 700
}

.note-input {
    width: 100%;
    display: block;
    border: 1px solid #ededef;
    background: #fff;
    outline: 0;
    padding: 6px 4px;
    font-size: 14px;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.note-input::-webkit-input-placeholder {
    color: #eee
}

.note-input:-moz-placeholder,
.note-input::-moz-placeholder {
    color: #eee
}

.note-input:-ms-input-placeholder {
    color: #eee
}

.note-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 13px;
    transition: opacity .15s;
    -webkit-opacity: 0;
    -khtml-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
    filter: alpha(opacity=0)
}

.note-tooltip.in {
    -webkit-opacity: .9;
    -khtml-opacity: .9;
    -moz-opacity: .9;
    opacity: .9;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
    filter: alpha(opacity=90)
}

.note-tooltip.top {
    margin-top: -3px;
    padding: 5px 0
}

.note-tooltip.right {
    margin-left: 3px;
    padding: 0 5px
}

.note-tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0
}

.note-tooltip.left {
    margin-left: -3px;
    padding: 0 5px
}

.note-tooltip.bottom .note-tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000
}

.note-tooltip.top .note-tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000
}

.note-tooltip.right .note-tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000
}

.note-tooltip.left .note-tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000
}

.note-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.note-tooltip-content {
    max-width: 200px;
    font-family: sans-serif;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000
}

.note-popover {
    position: absolute;
    z-index: 1060;
    display: block;
    font-size: 13px;
    font-family: sans-serif;
    display: none;
    background: #fff;
    border: 1px solid #ccc
}

.note-popover.in {
    display: block
}

.note-popover.top {
    margin-top: -10px;
    padding: 5px 0
}

.note-popover.right {
    margin-left: 10px;
    padding: 0 5px
}

.note-popover.bottom {
    margin-top: 10px;
    padding: 5px 0
}

.note-popover.left {
    margin-left: -10px;
    padding: 0 5px
}

.note-popover.bottom .note-popover-arrow {
    top: -11px;
    left: 20px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.note-popover.bottom .note-popover-arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff
}

.note-popover.top .note-popover-arrow {
    bottom: -11px;
    left: 20px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, .25)
}

.note-popover.top .note-popover-arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-bottom-width: 0;
    border-top-color: #fff
}

.note-popover.right .note-popover-arrow {
    top: 50%;
    left: -11px;
    margin-top: -10px;
    border-left-width: 0;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, .25)
}

.note-popover.right .note-popover-arrow:after {
    left: 1px;
    margin-top: -10px;
    content: " ";
    border-left-width: 0;
    border-right-color: #fff
}

.note-popover.left .note-popover-arrow {
    top: 50%;
    right: -11px;
    margin-top: -10px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, .25)
}

.note-popover.left .note-popover-arrow:after {
    right: 1px;
    margin-top: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff
}

.note-popover-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border: 11px solid transparent
}

.note-popover-arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    content: " ";
    border: 10px solid transparent
}

.note-popover-content {
    padding: 3px 8px;
    color: #000;
    text-align: center;
    background-color: #fff;
    min-width: 100px;
    min-height: 30px
}

.note-editor {
    position: relative
}

.note-editor .note-dropzone {
    position: absolute;
    display: none;
    z-index: 100;
    color: #87cefa;
    background-color: #fff;
    opacity: .95
}

.note-editor .note-dropzone .note-dropzone-message {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 28px;
    font-weight: 700
}

.note-editor .note-dropzone.hover {
    color: #098ddf
}

.note-editor.dragover .note-dropzone {
    display: table
}

.note-editor .note-editing-area {
    position: relative
}

.note-editor .note-editing-area .note-editable {
    outline: none
}

.note-editor .note-editing-area .note-editable sup {
    vertical-align: super
}

.note-editor .note-editing-area .note-editable sub {
    vertical-align: sub
}

.note-editor .note-editing-area .note-editable img.note-float-left {
    margin-right: 10px
}

.note-editor .note-editing-area .note-editable img.note-float-right {
    margin-left: 10px
}

.note-editor.note-airframe,
.note-editor.note-frame {
    border: 1px solid rgba(0, 0, 0, .2)
}

.note-editor.note-airframe.codeview .note-editing-area .note-editable,
.note-editor.note-frame.codeview .note-editing-area .note-editable {
    display: none
}

.note-editor.note-airframe.codeview .note-editing-area .note-codable,
.note-editor.note-frame.codeview .note-editing-area .note-codable {
    display: block
}

.note-editor.note-airframe .note-editing-area,
.note-editor.note-frame .note-editing-area {
    overflow: hidden
}

.note-editor.note-airframe .note-editing-area .note-editable,
.note-editor.note-frame .note-editing-area .note-editable {
    padding: 10px;
    overflow: auto;
    word-wrap: break-word
}

.note-editor.note-airframe .note-editing-area .note-editable[contenteditable=false],
.note-editor.note-frame .note-editing-area .note-editable[contenteditable=false] {
    background-color: hsla(0, 0%, 50.2%, .11)
}

.note-editor.note-airframe .note-editing-area .note-codable,
.note-editor.note-frame .note-editing-area .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: none;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    resize: none;
    outline: none;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0;
    margin-bottom: 0
}

.note-editor.note-airframe.fullscreen,
.note-editor.note-frame.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    z-index: 1050
}

.note-editor.note-airframe.fullscreen .note-resizebar,
.note-editor.note-frame.fullscreen .note-resizebar {
    display: none
}

.note-editor.note-airframe .note-status-output,
.note-editor.note-frame .note-status-output {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    height: 20px;
    margin-bottom: 0;
    color: #000;
    border: 0;
    border-top: 1px solid #e2e2e2
}

.note-editor.note-airframe .note-status-output:empty,
.note-editor.note-frame .note-status-output:empty {
    height: 0;
    border-top: 0 solid transparent
}

.note-editor.note-airframe .note-status-output .pull-right,
.note-editor.note-frame .note-status-output .pull-right {
    float: right !important
}

.note-editor.note-airframe .note-status-output .text-muted,
.note-editor.note-frame .note-status-output .text-muted {
    color: #777
}

.note-editor.note-airframe .note-status-output .text-primary,
.note-editor.note-frame .note-status-output .text-primary {
    color: #286090
}

.note-editor.note-airframe .note-status-output .text-success,
.note-editor.note-frame .note-status-output .text-success {
    color: #3c763d
}

.note-editor.note-airframe .note-status-output .text-info,
.note-editor.note-frame .note-status-output .text-info {
    color: #31708f
}

.note-editor.note-airframe .note-status-output .text-warning,
.note-editor.note-frame .note-status-output .text-warning {
    color: #8a6d3b
}

.note-editor.note-airframe .note-status-output .text-danger,
.note-editor.note-frame .note-status-output .text-danger {
    color: #a94442
}

.note-editor.note-airframe .note-status-output .alert,
.note-editor.note-frame .note-status-output .alert {
    margin: -7px 0 0;
    padding: 7px 10px 2px;
    border-radius: 0;
    color: #000;
    background-color: #f5f5f5
}

.note-editor.note-airframe .note-status-output .alert .note-icon,
.note-editor.note-frame .note-status-output .alert .note-icon {
    margin-right: 5px
}

.note-editor.note-airframe .note-status-output .alert-success,
.note-editor.note-frame .note-status-output .alert-success {
    color: #3c763d !important;
    background-color: #dff0d8 !important
}

.note-editor.note-airframe .note-status-output .alert-info,
.note-editor.note-frame .note-status-output .alert-info {
    color: #31708f !important;
    background-color: #d9edf7 !important
}

.note-editor.note-airframe .note-status-output .alert-warning,
.note-editor.note-frame .note-status-output .alert-warning {
    color: #8a6d3b !important;
    background-color: #fcf8e3 !important
}

.note-editor.note-airframe .note-status-output .alert-danger,
.note-editor.note-frame .note-status-output .alert-danger {
    color: #a94442 !important;
    background-color: #f2dede !important
}

.note-editor.note-airframe .note-statusbar,
.note-editor.note-frame .note-statusbar {
    background-color: hsla(0, 0%, 50.2%, .11);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid rgba(0, 0, 0, .2)
}

.note-editor.note-airframe .note-statusbar .note-resizebar,
.note-editor.note-frame .note-statusbar .note-resizebar {
    padding-top: 1px;
    height: 9px;
    width: 100%;
    cursor: ns-resize
}

.note-editor.note-airframe .note-statusbar .note-resizebar .note-icon-bar,
.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid rgba(0, 0, 0, .2)
}

.note-editor.note-airframe .note-statusbar.locked .note-resizebar,
.note-editor.note-frame .note-statusbar.locked .note-resizebar {
    cursor: default
}

.note-editor.note-airframe .note-statusbar.locked .note-resizebar .note-icon-bar,
.note-editor.note-frame .note-statusbar.locked .note-resizebar .note-icon-bar {
    display: none
}

.note-editor.note-airframe .note-placeholder,
.note-editor.note-frame .note-placeholder {
    padding: 10px
}

.note-editor.note-airframe {
    border: 0
}

.note-editor.note-airframe .note-editing-area .note-editable {
    padding: 0
}

.note-popover.popover {
    display: none;
    max-width: none
}

.note-popover.popover .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle
}

.note-popover.popover .arrow {
    left: 20px !important
}

.note-toolbar {
    position: relative
}

.note-editor .note-toolbar,
.note-popover .popover-content {
    margin: 0;
    padding: 0 0 5px 5px
}

.note-editor .note-toolbar>.note-btn-group,
.note-popover .popover-content>.note-btn-group {
    margin-top: 5px;
    margin-left: 0;
    margin-right: 5px
}

.note-editor .note-toolbar .note-btn-group .note-table,
.note-popover .popover-content .note-btn-group .note-table {
    min-width: 0;
    padding: 5px
}

.note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker,
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker {
    font-size: 18px
}

.note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute !important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer
}

.note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative !important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat
}

.note-editor .note-toolbar .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted,
.note-popover .popover-content .note-btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute !important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat
}

.note-editor .note-toolbar .note-style .dropdown-style blockquote,
.note-editor .note-toolbar .note-style .dropdown-style pre,
.note-popover .popover-content .note-style .dropdown-style blockquote,
.note-popover .popover-content .note-style .dropdown-style pre {
    margin: 0;
    padding: 5px 10px
}

.note-editor .note-toolbar .note-style .dropdown-style h1,
.note-editor .note-toolbar .note-style .dropdown-style h2,
.note-editor .note-toolbar .note-style .dropdown-style h3,
.note-editor .note-toolbar .note-style .dropdown-style h4,
.note-editor .note-toolbar .note-style .dropdown-style h5,
.note-editor .note-toolbar .note-style .dropdown-style h6,
.note-editor .note-toolbar .note-style .dropdown-style p,
.note-popover .popover-content .note-style .dropdown-style h1,
.note-popover .popover-content .note-style .dropdown-style h2,
.note-popover .popover-content .note-style .dropdown-style h3,
.note-popover .popover-content .note-style .dropdown-style h4,
.note-popover .popover-content .note-style .dropdown-style h5,
.note-popover .popover-content .note-style .dropdown-style h6,
.note-popover .popover-content .note-style .dropdown-style p {
    margin: 0;
    padding: 0
}

.note-editor .note-toolbar .note-color-all .note-dropdown-menu,
.note-popover .popover-content .note-color-all .note-dropdown-menu {
    min-width: 337px
}

.note-editor .note-toolbar .note-color .dropdown-toggle,
.note-popover .popover-content .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette {
    display: inline-block;
    margin: 0;
    width: 160px
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette:first-child,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette:first-child {
    margin: 0 5px
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-palette-title,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-palette-title {
    font-size: 12px;
    margin: 2px 7px;
    text-align: center;
    border-bottom: 1px solid #eee
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset,
.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-reset,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select {
    font-size: 11px;
    margin: 3px;
    padding: 0 3px;
    cursor: pointer;
    width: 100%;
    border-radius: 5px
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-reset:hover,
.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select:hover,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-reset:hover,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select:hover {
    background: #eee
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-row,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-row {
    height: 20px
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-color-select-btn,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-color-select-btn {
    display: none
}

.note-editor .note-toolbar .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn,
.note-popover .popover-content .note-color .note-dropdown-menu .note-palette .note-holder-custom .note-color-btn {
    border: 1px solid #eee
}

.note-editor .note-toolbar .note-para .note-dropdown-menu,
.note-popover .popover-content .note-para .note-dropdown-menu {
    min-width: 228px;
    padding: 5px
}

.note-editor .note-toolbar .note-para .note-dropdown-menu>div+div,
.note-popover .popover-content .note-para .note-dropdown-menu>div+div {
    margin-left: 5px
}

.note-editor .note-toolbar .note-dropdown-menu,
.note-popover .popover-content .note-dropdown-menu {
    min-width: 160px
}

.note-editor .note-toolbar .note-dropdown-menu.right,
.note-popover .popover-content .note-dropdown-menu.right {
    right: 0;
    left: auto
}

.note-editor .note-toolbar .note-dropdown-menu.right:before,
.note-popover .popover-content .note-dropdown-menu.right:before {
    right: 9px;
    left: auto !important
}

.note-editor .note-toolbar .note-dropdown-menu.right:after,
.note-popover .popover-content .note-dropdown-menu.right:after {
    right: 10px;
    left: auto !important
}

.note-editor .note-toolbar .note-dropdown-menu.note-check a i,
.note-popover .popover-content .note-dropdown-menu.note-check a i {
    color: #00bfff;
    visibility: hidden
}

.note-editor .note-toolbar .note-dropdown-menu.note-check a.checked i,
.note-popover .popover-content .note-dropdown-menu.note-check a.checked i {
    visibility: visible
}

.note-editor .note-toolbar .note-fontsize-10,
.note-popover .popover-content .note-fontsize-10 {
    font-size: 10px
}

.note-editor .note-toolbar .note-color-palette,
.note-popover .popover-content .note-color-palette {
    line-height: 1
}

.note-editor .note-toolbar .note-color-palette div .note-color-btn,
.note-popover .popover-content .note-color-palette div .note-color-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0
}

.note-editor .note-toolbar .note-color-palette div .note-color-btn:hover,
.note-popover .popover-content .note-color-palette div .note-color-btn:hover {
    transform: scale(1.2);
    transition: all .2s
}

.note-modal .modal-dialog {
    outline: 0;
    border-radius: 5px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.note-modal .form-group {
    margin-left: 0;
    margin-right: 0
}

.note-modal .note-modal-form {
    margin: 0
}

.note-modal .note-image-dialog .note-dropzone {
    min-height: 100px;
    font-size: 30px;
    line-height: 4;
    color: #d3d3d3;
    text-align: center;
    border: 4px dashed #d3d3d3;
    margin-bottom: 10px
}

.note-placeholder {
    position: absolute;
    display: none;
    color: grey
}

.note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid #000
}

.note-handle .note-control-selection>div {
    position: absolute
}

.note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-opacity: .3;
    -khtml-opacity: .3;
    -moz-opacity: .3;
    opacity: .3;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
    filter: alpha(opacity=30)
}

.note-handle .note-control-selection .note-control-handle,
.note-handle .note-control-selection .note-control-holder,
.note-handle .note-control-selection .note-control-sizing {
    width: 7px;
    height: 7px;
    border: 1px solid #000
}

.note-handle .note-control-selection .note-control-sizing {
    background-color: #000
}

.note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: none;
    border-bottom: none
}

.note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: none;
    border-left: none
}

.note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: none;
    border-right: none
}

.note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize
}

.note-handle .note-control-selection .note-control-se.note-control-holder {
    cursor: default;
    border-top: none;
    border-left: none
}

.note-handle .note-control-selection .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    color: #fff;
    background-color: #000;
    font-size: 12px;
    border-radius: 5px;
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
    filter: alpha(opacity=70)
}

.note-hint-popover {
    min-width: 100px;
    padding: 2px
}

.note-hint-popover .popover-content {
    padding: 3px;
    max-height: 150px;
    overflow: auto
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item {
    display: block !important;
    padding: 3px
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item.active,
.note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1.4;
    color: #fff;
    white-space: nowrap;
    text-decoration: none;
    background-color: #428bca;
    outline: 0;
    cursor: pointer
}

.note-editor .note-editing-area .note-editable table {
    width: 100%;
    border-collapse: collapse
}

.note-editor .note-editing-area .note-editable table td,
.note-editor .note-editing-area .note-editable table th {
    border: 1px solid #ececec;
    padding: 5px 3px
}

.note-editor .note-editing-area .note-editable a {
    background-color: inherit;
    text-decoration: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: #337ab7
}

.note-editor .note-editing-area .note-editable a:focus,
.note-editor .note-editing-area .note-editable a:hover {
    color: #23527c;
    text-decoration: underline;
    outline: 0
}

.note-editor .note-editing-area .note-editable figure {
    margin: 0
}

.note-modal .note-modal-body label {
    margin-bottom: 2px;
    padding: 2px 5px;
    display: inline-block
}

.note-modal .note-modal-body .help-list-item:hover {
    background-color: #e0e0e0
}

@-moz-document url-prefix() {
    .note-modal .note-image-input {
        height: auto
    }
}

.help-list-item label {
    margin-bottom: 5px;
    display: inline-block
}