.contenedor-cat-movil {
  overflow: hidden;
}

.carrusel-cats {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.contenedor-cat-movil {
  position: fixed;
  bottom: 0px;
  background-color: var(--azul-black);
  z-index: 999;
  padding-top: 4px;
  box-shadow: 2px 0px 6px rgba(0, 0, 0, 0.216);
  width: 100%;
}

