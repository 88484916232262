.icono-header-circular {
  background-color: #00bfd6;
  border-radius: 100%;
  width: 31px;
  height: 31px;
}

@media (max-width: 700px) {
  .posicionmv {
    padding-top: 0px;
    margin-top: 0px;
  }
}

.btn-ojo {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.input-pass {
  border: 0 !important;
  appearance: none;
  
  
}

.input-pass:focus {
  
  box-shadow: 0 0 0 0 !important;;
}

.input-group-pass {
  border: 1px solid #c1c1c1;
  position: relative;
  
}

.input-group-pass input:focus, input:focus-visible, input:active{
  border: 0 !important;
}

.a-borde-inf:hover {
  text-decoration: underline 2px solid black;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

.input-pass:focus, .input-pass:focus-visible, .input-pass:active{
  border: unset !important;
}