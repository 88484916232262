.mi-buscador {
  width: 340px;
  height: 36px;
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .mi-div {
    display: none;
  }

}

.text-turq {
  color: #00bfd6;
  text-decoration: none;
}

.text-turq:hover {
  color: #00bfd6 !important;
  text-decoration: none !important;
}

.div-listado-categorias {
  width: 250px;
  overflow-y: scroll;
  max-height: 85vh;
  scrollbar-width: thin;
  scrollbar-color: var(--grisC1) #c1c1c1;
  position: absolute;
  z-index: 3;
}

.borde-blanco-1px {
  width: 1px;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
  border-right: white solid 2px;
}

.div-listado-categorias::-webkit-scrollbar {
  width: 10px;
}

.div-listado-categorias::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.div-listado-categorias::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 100px;
}

.li-categoria {
  cursor: pointer;
}

.li-categoria:hover {
  border-left: 5px solid black;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
}

.logosize {
  width: 190px;
  height: 51px;
}

@media (max-width: 800px) {
  .logosize {
    width: 140px;
    height: 38px;
  }

  .marginmovile {
    margin-left: 0px;
    margin-top: 0px !important;
    padding-top: 0px !important;
  }

}

.header-fixed {
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 1000;
}

.popup-bg {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  display: block;
}

.popup-img {
  width: auto;
  max-width: 500px;
  height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1500;
  display: block;
  transform: translate(-50%, -50%);
}

.popup-x {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  cursor: pointer;
}