:root {
  --azul: #0b244a;
  --azulhover: #0e3d83;
  --cian: #00c2d9;
  --cianhover: #01b2c5;
  --size14: 16px;
  --size20: 20px;
  --size50: 50px;
}

@font-face {
  font-family: "GreycliffCF-DemiBold";
  src: url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-demibold.eot");
  src: url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-demibold.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-demibold.woff") format("woff"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-demibold.ttf") format("truetype"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-demibold.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-Heavy";
  src: url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-heavy.eot");
  src: url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-heavy.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-heavy.woff") format("woff"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-heavy.ttf") format("truetype"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-heavy.otf") format("opentype");
}

@font-face {
  font-family: "GreycliffCF-Medium";
  src: url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-medium.eot");
  src: url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-medium.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-medium.woff") format("woff"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-medium.ttf") format("truetype"),
    url("https://dreamtec.cl/Fontspring-DEMO-greycliffcf-medium.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("https://dreamtec.cl/Gilroy-ExtraBold.eot");
  src: url("https://dreamtec.cl/Gilroy-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Gilroy-ExtraBold.woff") format("woff"),
    url("https://dreamtec.cl/Gilroy-ExtraBold.ttf") format("truetype"),
    url("https://dreamtec.cl/Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("https://dreamtec.cl/Gilroy-Light.eot");
  src: url("https://dreamtec.cl/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Gilroy-Light.woff") format("woff"),
    url("https://dreamtec.cl/Gilroy-Light.ttf") format("truetype"),
    url("https://dreamtec.cl/Gilroy-Light.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("https://dreamtec.cl/Poppins-SemiBold.eot");
  src: url("https://dreamtec.cl/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Poppins-SemiBold.woff") format("woff"),
    url("https://dreamtec.cl/Poppins-SemiBold.ttf") format("truetype"),
    url("https://dreamtec.cl/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Saira-Medium";
  src: url("https://dreamtec.cl/Saira-Medium.eot");
  src: url("https://dreamtec.cl/Saira-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Saira-Medium.woff") format("woff"),
    url("https://dreamtec.cl/Saira-Medium.ttf") format("truetype"),
    url("https://dreamtec.cl/Saira-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Saira-SemiBold";
  src: url("https://dreamtec.cl/Saira-SemiBold.eot");
  src: url("https://dreamtec.cl/Saira-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://dreamtec.cl/Saira-SemiBold.woff") format("woff"),
    url("https://dreamtec.cl/Saira-SemiBold.ttf") format("truetype"),
    url("https://dreamtec.cl/Saira-SemiBold.otf") format("opentype");
}

.h1,
.sueno {
  font-family: "Gilroy-ExtraBold";
  font-size: var(--size50);
  line-height: 46px;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.sueno {
  margin-top: -10px;
  float: left;
  font-size: var(--size20) !important;
}

.bgBienvenido {
  background: url(https://dreamtec.cl/uploads/assets/industrial/slide_bienvenido.png) no-repeat;
  min-height: 336px;
  width: 100%;
}

.bgPlotter {
  background: url(https://dreamtec.cl/uploads/assets/industrial/slide_ploter.png) no-repeat center center;
  background-size: cover;
  /* Ajusta la imagen para que cubra todo el contenedor */
  min-height: 336px;
  width: 100%;
}

.bgMaquinasbordar {
  background: url(https://dreamtec.cl/uploads/assets/industrial/maquinas_bordar.png) no-repeat center center;
  background-size: cover;
  /* Ajusta la imagen para que cubra todo el contenedor */
  min-height: 336px;
  width: 100%;
}

.bgImpresora {
  background: url(https://dreamtec.cl/uploads/assets/industrial/slide_impresora_textil.png) no-repeat center center;
  background-size: cover;
  /* Ajusta la imagen para que cubra todo el contenedor */
  min-height: 336px;
  width: 100%;
}

.fs_16_industrial {
  font-size: 16px !important;
}

.l_h_20_industrial {
  line-height: 20px !important;
}

/* .text_shadow_industrial {
  text-shadow: 0 1px black;
} */

.pb_30 {
  padding-bottom: 30px !important;
}

.marca {
  max-width: 150px;
}

.fs-7 {
  font-size: 15px;
}

.bg-body-tertiary {
  background-color: var(--azul) !important;
}

.border-primary-subtle,
.border-primary-subtle {
  border-color: var(--azul) !important;
}

.industrial {
  height: 64px;
  width: auto;
}

.p_industrial {
  font-family: "Gilroy-Light";
  font-size: 18px;
}

.input_industrial,
.select_industrial,
.textarea_industrial {
  background-color: #efefef !important;
}

.input_industrial::placeholder {
  color: rgb(188, 188, 188) !important;
}

.inferior {
  width: 100% !important;
  margin-bottom: -4px;
  border: 2px var(--azul) solid !important;
}

.btn-primary_industrial {
  background-color: var(--cian);
  color: white;
  border-radius: 5px;
  border: 2px solid var(--cian);
  width: 200px;
  height: 45px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 8px;
}

.btn-primary_industrial:hover {
  background-color: var(--cianhover);
  color: white;
  border-radius: 5px;
  border: 2px solid var(--cianhover);
  width: 200px;
  height: 45px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 8px;
}

.especialwidth {
  width: 100% !important;
}

.btn-secondary_industrial {
  background-color: var(--azul);
  color: white;
  border-radius: 5px;
  border: 2px solid var(--azul);
  width: 200px;
  height: 45px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 8px;
}

.btn-secondary_industrial:hover {
  background-color: var(--azulhover);
  color: white;
  border-radius: 5px;
  border: 2px solid var(--azulhover);
  width: 200px;
  height: 45px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 8px;
}

.links_footer_industrial {
  margin-bottom: 30px;
  border-bottom: #00c2d9 !important;
}

.textfooter {
  font-size: var(--size14);
}

footer h4 {
  font-family: "Gilroy-ExtraBold";
}

footer p b {
  font-family: "Gilroy-ExtraBold";
}

footer p {
  font-family: "Gilroy-Light";
}

.shadow-0{
  text-shadow: none !important;
}

.azul_oscuro{
  color: #0b244a !important;
}

@media (max-width: 1199px){
  .w-100-movil{
    width: 100% !important;
  }
}

@media (max-width: 588px) {

  .h1,
  .sueno {
    font-size: 40px;
  }

}

@media (max-width: 400px){

  .h1,
  .sueno {
    font-size: 35px;
  }

}