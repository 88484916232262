.carousel-indicators button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 100%;
  border: none;
  background-color: rgba(0, 0, 0, 0.6);
  margin-right: 5px;
  margin-left: 5px;
  transition: transform 0.15s ease-in-out;
  cursor: pointer;
}

.carousel-indicators [data-bs-target] {
  width: 12px !important;
  height: 12px !important;
  border-radius: 100% !important;
  background-color: white;
  opacity: 1;
}

.carousel-indicators .active {
  background-color: #00bfd6;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 48px !important;
  height: 48px !important;
  background-size: 100% !important;
  background-color: rgba(0, 0, 0, 0.229) !important;
  border-radius: 200px !important;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.673);
}

.carousel-control-prev-icon {
  background: url(https://dreamtec.cl/uploads/assets/home/flecha-slide-izq.png) !important;
  background-size: contain !important;
}

.carousel-control-next-icon {
  background: url(https://dreamtec.cl/uploads/assets/home/flecha-slide-der.png) !important;
  background-size: contain !important;
}