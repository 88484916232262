.btn_aceptar_domicilio {
  background-color: #131f35;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: white;
}

.btn_aceptar_domicilio:disabled,
.btn_aceptar_single:disabled {
  background-color: #c1c1c1;
  color: black;
}

@media (max-width: 768px) {
  .centermovile {
    margin-left: 25px !important;
  }
  .bordernone {
    border-right: 0px solid #dee2e6 !important;
  }
  .centermoviletext {
    margin-left: 45px !important;
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media (max-width: 1000px) {
  .modal-dialog {
    display: block !important;
  }
  
}



