.align-items-center {
  vertical-align: middle;
  align-items: center;
}


.f-16 {
  font-size: 16px !important;
}


.f-20 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.icono-nombre-marca {
  font-size: 10px;
  color: #00bfd6;
}

#slide-movil .carousel-indicators button {

  border: 1px solid gray;

}

.color-35 {
  color: rgba(0, 180, 216, 1) !important;
}

.col-12.mt-3 .p iframe {
  width: 100% !important;
}

iframe {
  width: 100% !important;
}

.spinner-detail {
  --bs-spinner-width: 6rem;
  --bs-spinner-height: 6rem;
}


.nombre-marca {
  color: #035ba8;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  width: fit-content;
  padding: 1px 8px;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  margin-bottom: 8px !important;
  margin-top: 0px;
}

.icono-nombre-marca {
  font-size: 10px;
  color: #00bfd6;
}

.sku-detalle {
  border-radius: 10px;
  border: 1px solid #00bfd6;
  color: #003f66;
  padding: 8px;
  font-size: 16px;
  font-family: "rubik", sans-serif;
}

.img-producto-miniatura {
  object-fit: contain;
  height: auto;
}

.cursor_pointer {
  cursor: pointer;
}

.rect-img-container-b {
  border: 1px solid #f7f7f7;
  margin-bottom: 8px;
  height: 50px;
}

.rect-img-container-b .active {
  border: 1px solid #f7f7f7;
  border-left: 3px solid #00bfd6 !important;
}

.tdeconone {
  text-decoration: none;
}

.text-azul {
  color: #035ba8;
}

.text-azul-black {
  color: #131f35;
  font-size: 12px;
}

.texto-precio-transf {
  color: white;
  background-color: #035ba8;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
}

.link-tab-producto {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin: 15px 0px;
  padding: 10px;
  color: black !important;
  background-color: white;
}

.nav-item .active {
  background-color: #00b4d8 !important;
  color: white !important;
}

.link-tab-producto:hover {
  background-color: #A1AABA !important;
}

.nav-item .active:hover {
  background-color: #00b4d8 !important;
  color: white !important;
}

.nav-tabs {
  border-bottom: none !important;
}

.icono-construccion {
  background-color: #00bfd6;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  align-content: center;
  display: inline-grid;
  margin: 0 auto;
  align-self: center;
  align-items: center;
  font-size: 60px;
}

.fixed-footer {
  margin-bottom: 20px;
}

.fz-14 {
  font-size: 14px;
}

.fz-12 {
  font-size: 12px;
}

.col-caract-dest {
  background-color: #f0f0f0;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  width: 96% !important;
  margin-left: auto;
  margin-right: auto;
}

.col-caract-dest p {
  background: url(https://dreamtec.cl/uploads/assets/circulo-rango-turq.png) no-repeat left center;
  background-size: 7px;
  padding-left: 12px;
}

.p-titulo-caract-dest {
  background: none !important;
}

.rounded1 {
  border-radius: 1rem !important;

}

.pagination-active {
  background-color: #00bfd6 !important;
}

.accordion-button {
  display: inline-table;
}

.collapsed {
  color: #00bfd6 !important;
}

.accordion-button {
  color: black;
}

.tab-pane.fade.show.active .row .col-12.mt-3 p img,
.col-12.mt-3 b p span,
.col-12.mt-3 b p span img,

.col-12.mt-3 div img {
  max-width: 100% !important;
  height: auto;
}


@media screen and (max-width:500px) {
  .col-12.mt-3 p span {
    max-width: 300px !important;
    height: auto;
  }
}

.link-tab-producto .texto-opciones {
  font-size: 16px;
  font-weight: normal;
  margin: 0px;
  color: black;
  line-height: unset;
}

.active .texto-opciones {
  color: white;
}


@media screen and (max-width: 768px) {
  .d-xs-none {
    display: none !important;
  }
}

.contador-fotos-detalle {
  border-radius: 200px;
  background-color: #E5E5E5;
  width: auto;
}

.carousel-item--detalle-movil {
  height: 280px;
  width: 100%;
  object-fit: contain;
}

.carousel-item--img-detalle-movil {
  height: 280px;
  width: 100%;
  object-fit: contain;
}

.texto-contador-fotos-detalle {
  color: var(--negro);
  font-size: var(--font12);
}

.Desc2 {
  background: rgb(0, 183, 223);
  background: -moz-linear-gradient(33deg, rgba(0, 183, 223, 1) 20%, rgba(26, 90, 167, 1) 49%, rgba(0, 183, 223, 1) 80%);
  background: -webkit-linear-gradient(33deg, rgba(0, 183, 223, 1) 20%, rgba(26, 90, 167, 1) 49%, rgba(0, 183, 223, 1) 80%);
  background: linear-gradient(33deg, rgba(0, 183, 223, 1) 20%, rgba(26, 90, 167, 1) 49%, rgba(0, 183, 223, 1) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00b7df", endColorstr="#00b7df", GradientType=1);
}

.Desc {
  background-color: #035ba8;
}

.Gris {
  color: #ACA8A9;
}

@media (max-width: 1399px) {

  .f-16 {
    font-size: 15px !important;
  }

  .f-24 {
    font-size: 16px !important;
  }

  .f-20 {
    font-size: 18px !important;
  }


}


@media (max-width: 767px) {


  .f-16 {
    font-size: 16px !important;
  }

  .f-24 {
    font-size: 24px !important;
  }

  .f-20 {
    font-size: 20px !important;
  }

}


@media (max-width: 400px) {

  .f-16 {
    font-size: 15px !important;
  }

  .f-24 {
    font-size: 16px !important;
  }

  .f-20 {
    font-size: 16px !important;
  }

}