/* @import url('https://fonts.cdnfonts.com/css/myriad-pro'); */

.fondo{
    
    background-color: #1c1c1c !important;
}

.fs-17 {
    font-size: 17px !important;
}

.l-h-27 {
    line-height: 20px;
}

.text-turq {
    color: #00bfd6;
    text-decoration: none;
}