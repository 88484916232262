.btn-borde-turq {
  border: 2px solid #00b4d8;
  background-color: transparent;
  color: #00b4d8;
}

.btn-borde-turq:hover {
  border: 2px solid #00b4d8;
  background-color: #00b4d8;
  color: white;
}


.fs-14{
  font-size: 14px;
}

.fs-18{
  font-size: 18px;
}

.resize{
  resize: none !important;
}

.btn-borde-turq:hover {
  border: 2px solid #00bfd6;
  background-color: #00bfd6;
  color: white !important;
}