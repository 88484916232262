.search-results {
    position: absolute;
    z-index: 1;
}

.max-height-4 {
    max-height: 300px;
    overflow-y: auto;
}

.max-w-4 {
    max-width: 400px !important;
    overflow-x: hidden;
}

.input-search::placeholder {
    font-style: italic;
    font-size: 14px;
}

.w-400 {
    width: 400px !important;
}