.borde-gris {
  border: 4px solid #e5e5e5;
}

.borde-round-20 {
  border-radius: 20px;
}

.margin-l {
  border-left: 1px solid black;
}

button:disabled{
  background-color: #00b4d8 !important;
}

.campo-alerta{
  font-size: 14px;
  color: red !important;
  font-weight: bold;
}

