.bg-gray {
  background-color: rgb(244, 244, 244);
  color: rgb(193, 193, 193);
  border-color: rgb(244, 244, 244);
  border-radius: 10px !important;
}

.text-gray{
  color: rgb(193, 193, 193);
}

.round-5 {
  border-radius: 10px !important;
  border: 2px solid turquoise !important;
}



.bottom-8{
  bottom: 8%;
}

.small{
  font-size: small;
}

.mt-6{
  margin-top: 75px;
}

.bg-blue{
  background-color: rgb(105, 105, 231);
}

.margin-x{
  margin-right: 30px;
}

.text-center-paso-2-movil{
  
}

@media (max-width: 991px){

  .text-center-paso-2-movil{
    text-align: center !important;
  }


}

@media screen and (max-width: 768px) {

  .bottom-0-movil-p2{
    position: initial !important;
    bottom: 0% !important;
  }

  .d-xs-none {
    display: none !important;
  }
  .mr-mv{
    margin-bottom: 25px;
  }

  
}